import React, {useState, useEffect} from 'react'

import classes from './ProductTabs.module.scss'

import infoImg from '@/assets/img/color_img.jpg'

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import { useTranslation  } from 'react-i18next'
import axios from 'axios'

type ProductTabsType = {
    type: string,
    opis: string,
    bgc: string,
    sposib_zastosuvannya?: string,
    aktivni_ingredinti?: string,
    rezultat_dlya_setiv?: string,
    protokol_proczeduri?: string,
    content_image?: string,
}
const ProductTabs:React.FC<ProductTabsType> = ({type, opis, bgc, sposib_zastosuvannya, aktivni_ingredinti, rezultat_dlya_setiv, protokol_proczeduri, content_image}) => {
    const [contentImg, setContentImg] = useState<string>('')
    const {t, i18n } = useTranslation()
    const [loaded, setLoaded] = useState<boolean>(false)

    const findCopntentAttachment = async() =>{
        //setContentImg("https://api.apicef.space/wp-content/uploads/2025/03/cat.jpg") 
        
        await axios(`https://api.apicef.space/wp-json/wp/v2/media/${content_image}`)
        .then((res: any) => {
           setContentImg(res.data.source_url) 
        }) 
    }
    useEffect(()=>{
        findCopntentAttachment()
    }, [content_image])

    useEffect(()=>{
        console.log(i18n.language);
        setLoaded(true)
    }, [i18n])

  if(loaded) return (
    <div className={bgc === 'violet' ? `${classes.product_color_Info} ${classes.violetBg}` : `${classes.product_color_Info}`}>
        <div className="container">
        <div className={classes.product_color_InfoBox}>
            <div className={classes.color_Info_left}>
                <h2>{i18n.language === "en" ? 'Overview' : 'огляд'}</h2>
                <div className={classes.product_tabs}>
                <Tabs>
                    <TabList>
                    <Tab>{i18n.language === "en" ? 'About' : 'Опис'}</Tab>
                        {type !== 'set' &&
                            (<>
                                <Tab>{i18n.language === "en" ? 'How to use' : 'Спосіб застосування'}</Tab>
                                <Tab>{i18n.language === "en" ? 'Active ingredients' : 'Активні інгредієнти'}</Tab>
                            </>)
                        } 
                        {type === 'set' &&
                            (<>
                                <Tab>{i18n.language === "en" ? 'Result' : 'результат'}</Tab>
                                <Tab>{i18n.language === "en" ? 'How to use' : 'протокол процедури'} </Tab>
                            </>)
                        } 
                     
                        
                    </TabList>

                    <TabPanel>
                        <div className="tabContent" dangerouslySetInnerHTML={{__html: opis}}>
                           
                        </div>
                    </TabPanel>

                    {sposib_zastosuvannya && (<TabPanel>
                        <div className="tabContent" dangerouslySetInnerHTML={{__html: sposib_zastosuvannya}}></div>
                    </TabPanel>)}
                    {aktivni_ingredinti && (<TabPanel>
                        <div className="tabContent" dangerouslySetInnerHTML={{__html: aktivni_ingredinti}}></div>
                    </TabPanel>)}
                    {rezultat_dlya_setiv && (<TabPanel>
                        <div className="tabContent" dangerouslySetInnerHTML={{__html: rezultat_dlya_setiv}}></div>
                    </TabPanel>)}
                    {protokol_proczeduri && (<TabPanel>
                        <div className="tabContent" dangerouslySetInnerHTML={{__html: protokol_proczeduri}}></div>
                    </TabPanel>)}
                    
                    
                </Tabs>
                </div>
            </div>
            <div className={classes.color_Info_right}>
                <img src={contentImg} alt="" />
            </div>
        </div>
    </div>
    </div>
  )
}
export default ProductTabs;